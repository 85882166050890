import type { FieldRenderProps } from 'react-final-form';
import type { ReactNode } from 'react';
import { FORM_ERROR } from 'final-form';

import { formatNumber } from 'helpers/formatting';
import { getPropertyAccessorFromObjectString } from 'components/AdForm/helpers/adFormMethods';
import { PriceTypes } from 'components/AdForm/types/index';

export function handleErrorMessage(meta: FieldRenderProps<any>['meta']) {
  const touched = meta.touched;
  const hasError =
    (meta.error && !meta.dirtySinceLastSubmit) ||
    (meta.submitError && !meta.dirtySinceLastSubmit);
  return (
    (touched && hasError && meta.error) ||
    (touched && hasError && meta.submitError)
  );
}

export function handleErrorMessageOnBlur(meta: FieldRenderProps<any>['meta']) {
  const touched = meta.touched;
  return (touched && meta.error) || (touched && meta.submitError);
}

export function handleErrorMessageOnChange(
  meta: FieldRenderProps<any>['meta'],
) {
  return meta.error || meta.submitError;
}

type ErrorObject422 = {
  code: number;
  field: string;
  message: string;
};

export function format422ErrorsFromApiForForm(errors: ErrorObject422[]) {
  function deDuplicateArray(error: ErrorObject422[]) {
    return [...new Set(error)];
  }

  const deDuplicatedArray = deDuplicateArray(errors);

  const formatted = deDuplicatedArray.reduce(
    (formattedErrors: any, currentError: ErrorObject422) => {
      /* adAttribute 422 errors are returned with field name formatted as
      'adAttributes[fieldName]. This must be reformatted as follows for React Final Form:
      {
         attributes: {
          fieldName: value,
         },
       }; */
      if (currentError.field.indexOf('[') !== -1) {
        /* Retrieve field name found between brackets where the field name
        is in the format 'attributes[${fieldName}]' */
        const fieldName = getPropertyAccessorFromObjectString(
          currentError.field,
        );
        formattedErrors['attributes'] = {
          ...formattedErrors['attributes'],
        };
        formattedErrors['attributes'][fieldName] = currentError.message;
      } else if (currentError.field.indexOf('.') !== -1) {
        let parts = currentError.field.split('.');
        formattedErrors[parts[0]] = {
          [parts[1]]: currentError.message,
        };
      } else {
        formattedErrors[currentError.field] = currentError.message;
      }
      return formattedErrors;
    },
    <{ [key: string]: string }>{},
  );

  return formatted;
}

export function formatFormSubmissionError(errorMessage: string | ReactNode) {
  return { [FORM_ERROR]: errorMessage };
}

export function removeNonNumericCharacters(value?: any) {
  if (value === PriceTypes.ON_REQUEST) return value;
  return value?.replace(/[^0-9]/g, '');
}

export function formatFormNumber(value?: any) {
  if (value === PriceTypes.ON_REQUEST) return value;
  return value ? formatNumber(value) : '';
}

// https://github.com/final-form/react-final-form/issues/130#issuecomment-425482365
export function keepEmptyStringAsFormValue(value: string) {
  return value;
}

type TFormattedErrors = {
  [key: string]: string | { [key: string]: string };
};

/**
 * When an invalid car reg is submitted and the user continues to submit the ad, 422 errors are returned from BE.
 * We pass up attributes[registrationNumber] and BE expects required field values for vehicle make and year, so will return this error:
 * [{
 *  code: 0
 *  field: "adAttributes[make]"
 *  message: "Please enter the vehicle make."
 * },
 * {
 * code: 0
 * field: "adAttributes[year]"
 * message: "Please enter the vehicle year"
 * }]
 * As these fields are not displayed we instead should return a field error for the reglookup.
 */
export function mapVehicleAttributeErrorsToRegLookupError(
  formattedErrors: TFormattedErrors,
  manualInputView: boolean,
) {
  if (
    !manualInputView &&
    formattedErrors?.attributes &&
    (formattedErrors.attributes as { [key: string]: string })?.make
  ) {
    // add new error for reg lookup
    const updatedErrors = {
      ...formattedErrors,
      attributes: {
        ...(formattedErrors.attributes as {
          [key: string]: string;
        }),
        registrationNumber: 'Please enter a valid registration',
      },
    };
    return updatedErrors;
  } else {
    return formattedErrors;
  }
}

/**
 * Will prevent default behaviour of form submission when pressing `Enter` if any form input field is active.
 * @param event passed from onKeyPress form element prop
 */
export function preventActiveInputFormSubmit(event: React.KeyboardEvent) {
  if (
    event.key === 'Enter' &&
    (event.target as Element)?.nodeName === 'INPUT'
  ) {
    event.preventDefault();
  }
}
